import $ from "jquery"
import lodash from "lodash"
import moment from "moment"

import assignGlobals from "utils/assign_globals"

const jQuery = $
assignGlobals({
  $,
  jQuery,
  lodash,
  moment,
})
